import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";

const BiznesConsult = () => {
  return (
    <div>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto max-w-6xl container p-8 bg-white space-y-4">
          <h2>Бизнес-консультирование</h2>
          <div className="news-text">
            <p>
              Учредители и сотрудники агентства имеют возможность получать
              инсайдерскую информацию в самых различных сферах как в Республике
              Татарстан, так и за ее пределами, и на ее основе предлагать
              решения существующих проблем как для малого бизнеса, так и крупных
              предприятий. Мы гарантируем индивидуальный подход и
              конфиденциальность каждому клиенту.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BiznesConsult;
